<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap v-if="story" pa-5>
      <v-flex xs6>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >Our Story</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 text-right py-5>
        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          persistent
          max-width="800px"
          :key="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark color="green" v-bind="attrs" v-on="on"> UPDATE </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Updates Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <span>Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="story.description"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <ImageComp
                        :singleImage="story.image"
                        @stepper="winStepper"
                        :heading="'Upload Image'"
                        :componentType="'aboutBannerImage'"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="update()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>

      <v-flex xs12 sm6 md8 pa-2 align-self-center v-if="story.description">
        <span
          style="
            font-family: poppinsregular;
            font-size: 14px;
            text-align: justify;
          "
          v-html="story.description"
        ></span>
      </v-flex>
      <v-flex xs12 sm6 md4>
        <v-img :src="mediaUURL + story.image" contain height="200px"></v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/Common/singleImages";
export default {
  components: {
    VueEditor,
    ImageComp,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      aboutBannerImage: "",
      story: {},
      itemediting: [],
      editdialog: false,
      editingitem: {},
      officials: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "aboutBannerImage") {
        this.aboutBannerImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/view/website/content",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.story = response.data.websiteDetails.ourStory;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    update() {
      var data = {};
      data["description"] = this.story.description;
      axios({
        url: "/edit/ourStory",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.aboutBannerImage) {
              this.uploadBannerImage();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadBannerImage() {
      let formData = new FormData();
      formData.append("photo", this.aboutBannerImage);
      axios({
        method: "POST",
        url: "/ourStory/image/addOrEdit",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  <style scoped>
.item_title {
  font-weight: bold;
  font-family: poppinssemibold;
}
</style>
    